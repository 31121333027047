import React from "react"
import articleStyles from"./articlesnippet.module.scss"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function ArticleSnippet(props) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "skills/product-sense/jtbd-core-functions.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <div className={articleStyles.article}>
        <p className={articleStyles.articleTitle}>Excerpt from {props.articleType}<br/><span>{props.articleTitle}</span></p>
        <div className={articleStyles.articleContent}>
            <p className={articleStyles.articleAbove}>{props.articleAbove}</p>
            <h1 className={articleStyles.articleText}>{props.articleText}</h1>
            {props.author === "Tony Ulwick" &&
              <Img
                className={articleStyles.articleImage}
                fluid={data.file.childImageSharp.fluid}
                alt={props.articleImageAlt}
              />
            }
            {props.children}
            <p className={articleStyles.articleBelow}>{props.articleBelow}</p>
            <p className={articleStyles.articleLink}><a href={props.articleLink} target="_blank" rel="noreferrer">Read More</a><span className={articleStyles.author}>by {props.author}</span><br/><span className={articleStyles.publisher}>{props.publisher}</span></p>
        </div>
    </div>
  )
}